import React from 'react'
import Team from '../static/images/kamion1.png'
import '../static/css/index.css'
import '../static/css/home.css'
import { Reveal } from "../utils/Reveal";
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";


const Home = () => {

    return (
        <div className="main-container">


            <Helmet>
                <title>Drive4Nena - Excellence in Transportation Solutions</title>
                <meta name="description" content="Drive4Nena provides dependable and efficient logistics for solo and team drivers, offering competitive pay and support." />
                <meta name="keywords" content="Drive4Nena, trucking, transportation, logistics, driver jobs" />
            </Helmet>


            <Navbar />
            <div class="custom-shape-divider-top-1729358274">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                </svg>
            </div>
            <div className="home" id="home">
                <div className="team">
                    <img className="team-pic" src={Team} alt="Drive4Nena trucking equipment"></img>
                </div>
                <div className="desc-container">
                    <div className="desc-header">
                        <Reveal>
                            <div className="welcome">
                                <h1>Created by a Driver, for Drivers!</h1>


                            </div>
                        </Reveal>
                    </div>
                    <div className="desc-text">
                        <Reveal>
                            At <span style={{ color: 'var(--primary-color)' }}>Drive4Nena</span>, established in <span style={{ color: 'var(--primary-color)' }}>2006</span>, we are driven by the pursuit of excellence in transportation solutions.
                            We understand the challenges facing the trucking industry and continuously invest in advanced technology and reliable equipment to stay ahead of the curve.
                            Our mission is to deliver dependable, efficient logistics services for every need, <span style={{ color: 'var(--primary-color)' }}>on time, every time</span>.
                            <br /> Whether you're an independent contractor or a dedicated driver looking for solo or team opportunities, Drive4Nena ensures competitive pay, stable freight, and the support you need to succeed on the road.
                        </Reveal>
                    </div>
                    <div className="button-container">
                        <Reveal>
                            <Link to="/contact">
                                <button className="button-style" aria-label="Call a recruiter at Drive4Nena">CALL A RECRUITER</button>
                            </Link>
                        </Reveal>
                        <a href="http://www.driver-reach.com/l/m27d2" target="_blank" rel="noopener noreferrer">
                            <Reveal>
                                <button className="button-style button-cta" aria-label="Apply now to Drive4Nena">APPLY NOW</button>
                            </Reveal>
                        </a>
                    </div>
                </div>
            </div>
            <div className="home-mobile" id="home-mobile">
                <div className="desc-header">
                    <Reveal>
                        <div className="welcome">
                            <h1 className="header-text">
                                Created by a Driver, for Drivers!
                            </h1>
                        </div>
                    </Reveal>
                </div>
                <div className="desc-text">
                    <Reveal>
                        At <span style={{ color: 'var(--primary-color)' }}>Drive4Nena</span>, established in <span style={{ color: 'var(--primary-color)' }}>2006</span>, we are driven by the pursuit of excellence in transportation solutions.
                        We understand the challenges facing the trucking industry and continuously invest in advanced technology and reliable equipment to stay ahead of the curve.
                        Our mission is to deliver dependable, efficient logistics services for every need, <span style={{ color: 'var(--primary-color)' }}>on time, every time</span>.
                        <br /> Whether you're an independent contractor or a dedicated driver looking for solo or team opportunities, Drive4Nena ensures competitive pay, stable freight, and the support you need to succeed on the road.
                    </Reveal>
                </div>
                <div className="team">
                    <img className="team-pic" src={Team} alt="Drive4Nena trucking equipment"></img>
                </div>
                <div className="button-container">
                    <Link to="/contact"><Reveal>
                        <button className="button-style" aria-label="Call a recruiter at Drive4Nena">CALL A RECRUITER</button>
                    </Reveal></Link>
                    <a href="http://www.driver-reach.com/l/m27d2" target="_blank" rel="noopener noreferrer">
                        <Reveal>
                            <button className="button-style button-cta" aria-label="Apply now to Drive4Nena">APPLY NOW</button>
                        </Reveal>
                    </a>

                </div>
            </div>

            <Footer />

        </div>

    )
}

export default Home;