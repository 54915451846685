import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import '../static/css/index.css';
import '../static/css/testimonials.css';
import { Reveal } from "../utils/Reveal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import PorfileIimage1 from '../static/images/profileImage1.jpg';
import PorfileIimage2 from '../static/images/profileImage2.jpg';
import PorfileIimage3 from '../static/images/profileImage3.jpg';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Helmet } from 'react-helmet';

const Testimonials = () => {
    const testimonialData = [
        {
            quote: "Working with Drive4Nena has been a fantastic experience. They truly prioritize driver safety and provide the resources we need to stay safe and productive.",
            name: "Brian Hernandez",
            image: PorfileIimage1,
            waveClass: "wave-1"
        },
        {
            quote: "Dispatch is always clear and organized, and they respect my time both on and off the clock. It feels good to work with a company that has my back!",
            name: "Xavier Brown",
            image: PorfileIimage2,
            waveClass: "wave-2"
        },
        {
            quote: "They have great routes, fair pay, and a supportive team that's there whenever I have questions or need assistance. This makes a big difference day-to-day.",
            name: "Deron Wilford",
            image: PorfileIimage3,
            waveClass: "wave-3"
        }
    ];

    const TestimonialCard = ({ data }) => (
        <div className="testimonial-card">
            <div className="quote">{data.quote}</div>
            <div className="profile">
                <img src={data.image} alt={data.name} className="profile-image" />
                <h3 className="profile-name">{data.name}</h3>
                <div className="social-links">
                    <div><FontAwesomeIcon icon={faFacebook} className="icon-testimonial" /></div>
                    <div><FontAwesomeIcon icon={faLinkedin} className="icon-testimonial" /></div>
                </div>
            </div>
            <div className={data.waveClass}></div>
        </div>
    );

    // Convert testimonial cards to gallery items format
    const galleryItems = testimonialData.map(data => ({
        renderItem: () => <TestimonialCard data={data} />,
        originalClass: "testimonial-slide",
        thumbnailClass: "hidden"
    }));

    const gallerySettings = {
        showPlayButton: false,
        showFullscreenButton: false,
        showThumbnails: false,
        showBullets: true,
        infinite: true,
        showNav: true,
        autoPlay: false,
    };

    // Use window.innerWidth to determine if we should show gallery or grid
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 1200);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (

        <div className="main-container">
            <Helmet>
                <title>Testimonials | Drive4Nena</title>
                <meta
                    name="description"
                    content="Read what our customers have to say about their experience with Drive4Nena."
                />
            </Helmet>
            <Navbar />
            <div className="custom-shape-divider-top-1729358274">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                </svg>
            </div>
            <div className="testimonials" id="testimonials">
                <div className="section-header">
                    <h2>Customer reviews</h2>
                    <p>Trusted by clients nationwide, here's what our customers have to say about partnering with Drive4Nena.</p>
                </div>

                {isMobile ? (
                    <div className="testimonials-slider">
                        <ImageGallery
                            items={galleryItems}
                            {...gallerySettings}
                        />
                    </div>
                ) : (
                    <div className="testimonials-grid">
                        {testimonialData.map((data, index) => (
                            <TestimonialCard key={index} data={data} />
                        ))}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Testimonials;