import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import '../static/css/index.css'
import '../static/css/privacy.css'

const GeneralTermsOfService = () => {

    return (
        <div className="main-container">
            <Helmet>
                <title>General Terms of Service - Drive4Nena</title>
                <meta name="description" content="Review the General Terms of Service for Drive4Nena, outlining user rights, responsibilities, and the legal framework governing the use of our services and website." />
                <meta name="keywords" content="Drive4Nena terms of service, user responsibilities, prohibited activities, legal terms, liability, governing law" />
                <link rel="canonical" href="/terms-of-service" />
            </Helmet>


            <Navbar />

            <div className="privacy" id="privacy">
                <h1>1. General Terms of Service</h1>

                <h2>1.1 Introduction</h2>
                <p>Nena Trucking INC. operates this website to provide information about our trucking and logistics services and enable communication with customers. By using our website or services, you agree to these Terms of Service and our Privacy Policy.</p>
                <h2>1.2 User Rights and Responsibilities</h2>
                <p>Users are granted a limited, non-exclusive, and revocable license to access and use our website and services for lawful purposes. You are responsible for ensuring that your use of our website complies with all applicable laws and regulations.</p>
                <h2>1.3 Prohibited Activities</h2>
                <p>You agree not to:
                    <ul>
                        <li>Use our website for illegal purposes.</li>
                        <li>Upload or distribute harmful software, viruses, or malicious content.</li>
                        <li>Engage in activities that violate intellectual property rights or the privacy of others.</li>
                        <li>Attempt to gain unauthorized access to our systems or disrupt our operations.</li>
                    </ul>

                </p>

                <h2>1.4 Limitation of Liability</h2>
                <p>Nena Trucking INC. is not liable for any damages arising from your use of this website or our services, including but not limited to direct, indirect, incidental, or consequential damages.</p>
                <h2>1.5 Governing Law</h2>
                <p>These Terms of Service are governed by and construed under the laws of the State of Illinois, United States. Any disputes shall be resolved in the courts of Illinois.</p>

                <br></br>
                <br></br>
                <h1>2. SMS Terms & Conditions</h1>
                <h2>2.1 Messaging Frequency</h2>
                <p>By opting into our SMS messaging campaigns, you agree to receive up to 5 messages per week regarding our services, updates, or promotions.</p>
                <h2>2.2 Potential Fees</h2>
                <p>Message and data rates may apply based on your carrier and mobile plan. You are solely responsible for any fees incurred.</p>
                <h2>2.3 Opt-In and Opt-Out Methods</h2>
                <p>To opt-in to our SMS campaigns, text “START” to [phone number]. By opting in, you agree to receive messages from Nena Trucking INC. To opt-out, reply “STOP” to any SMS message, and you will no longer receive further messages. For help, text “HELP” to [phone number] or contact us at support@drive4nena.com.</p>

                <h2>2.4 Privacy Policy</h2>
                <p>By subscribing to our SMS messaging service, you agree to our
                    <span> <a href="/privacypolicy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a> </span>
                    , which explains how we collect, use, and protect your personal information.</p>


                <br></br>
                <br></br>
                <h1>3. Changes to Terms</h1>
                <p>Nena Trucking INC. reserves the right to update or modify these Terms of Service at any time. Any significant changes will be communicated via our website.</p>


                <br></br>
                <br></br>
                <h1>4. Contact Information</h1>
                <p>For questions regarding these Terms of Service or our SMS campaigns, contact us:
                    * Email: info@drive4nena.com
                    * Phone: 708-852-5587
                    * Address: 1 Westbrook Corporate Center, Suite 300, Westchester, IL 60154
                </p>


            </div>
            <Footer />
        </div>
    )
}

export default GeneralTermsOfService;