import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import '../static/css/index.css'
import '../static/css/privacy.css'

const PrivacyPolicy = () => {

    return (
        <div className="main-container">
            <Helmet>
                <title>Privacy Policy - Drive4Nena</title>
                <meta name="description" content="Learn how Drive4Nena collects, uses, and protects your personal information. Our Privacy Policy outlines your rights and how to contact us for privacy-related concerns." />
                <meta name="keywords" content="Drive4Nena privacy policy, data protection, user rights, personal information security, privacy practices" />
                <link rel="canonical" href="/privacy-policy" />
            </Helmet>

            <Navbar />

            <div className="privacy" id="privacy">
                <h1>NENA TRUCKING INC. Privacy Policy</h1>
                <p>Effective Date: 08.08.2024</p>
                <p>Nena Trucking INC is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you visit our website or interact with our services, including SMS messaging. By using our website and services, you consent to the practices described in this policy.</p>

                <br />
                <br />
                <h2>1. Data Collection</h2>

                <p>We may collect the following personal information:
                    <ul>
                        <li>Name, email address, and phone number.</li>
                        <li>Billing and shipping address, if applicable.</li>
                        <li>Any other information you voluntarily provide through forms or inquiries.</li>
                        <li>Technical data, such as IP address, browser type, and website usage statistics (collected via cookies or similar technologies).</li>
                    </ul>
                </p>

                <br />
                <br />
                <h2>2. Data Usage</h2>
                <p>We use the information collected for the following purposes:
                    <ul>
                        <li>To provide, operate, and improve our services.</li>
                        <li>To communicate with you, including sending SMS messages with your consent.</li>
                        <li>To respond to inquiries, process transactions, and provide customer support.</li>
                        <li>To comply with legal requirements and enforce our terms of service.</li>

                    </ul>
                    Important: SMS opt-in consent and phone numbers collected for SMS purposes will NOT be shared or sold to third parties or affiliates for marketing purposes.
                </p>



                <br />
                <br />
                <h2>3. Data Protection</h2>
                <p>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include:
                    <ul>
                        <li>Secure server environments.</li>
                        <li>Data encryption during transmission.</li>
                        <li>Regular monitoring and updates to our systems to address vulnerabilities.</li>
                    </ul>
                    However, no data transmission or storage system can be guaranteed to be 100% secure.
                </p>


                <br />
                <br />
                <h2> 4. User Rights</h2>

                <p>
                    You have the following rights regarding your personal information:
                    <ul>
                        <li>Access: Request access to the data we have about you.</li>
                        <li>Correction: Request corrections to your personal data if it is inaccurate.</li>
                        <li>Deletion: Request the deletion of your personal information.</li>
                        <li>Restriction: Limit how we use your data.</li>
                        <li>Objection: Object to data processing for specific purposes.</li>
                    </ul>
                    To exercise your rights, please contact us at privacy@drive4nena.com.
                </p>



                <br />
                <br />
                <h2>5. SMS Consent and Opt-Out</h2>
                <p>
                    <ul>
                        <li>To opt into receiving SMS messages, you must provide explicit consent.</li>
                        <li>We do not sell or share any SMS consent or personal information to third parties or affiliates for SMS marketing purposes.</li>
                        <li>To opt out of SMS messages, you may reply STOP to any SMS or contact us directly at support@drive4nena.com.</li>
                        <li>Message and data rates may apply.</li>
                    </ul>
                </p>

                <br />
                <br />
                <h2>6. Contact Information</h2>

                <p>
                    For privacy-related inquiries, please contact us at:
                    <ul>
                        <li>Email: info@drive4nena.com</li>
                        <li>Phone: 708-852-5587</li>
                        <li>Address: 1 Westbrook Corporate Center, Suite 300, Westchester, IL 60154</li>
                    </ul>
                </p>


                <br />
                <br />
                <h2>7. Policy Updates</h2>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any significant changes by posting an updated policy on our website with a revised “Effective Date.”</p>

                <br />
                <br />
                <h2>8. Accessibility</h2>
                <p>This Privacy Policy is accessible via a link in the footer of our website and can be provided in alternative formats upon request by contacting support@drive4nena.com.</p>

                <br />
                <p>Thank you for trusting Nena Trucking INC with your personal information.</p>

                <Link to="/contact">
                    <button className="button-style" aria-label="Go back to contact page">GO BACK</button>
                </Link>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy;