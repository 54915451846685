import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { motion, useInView, useAnimation } from "framer-motion";

export const Reveal = ({ children, width = 'fit-content' }) => {
    const ref = useRef(null);   //Koristimo React hook kako bismo napravili referencu ka odredjenom elementu
    const isInView = useInView(ref, { once: true });          // Ukoliko ne ubacimo once:true svaki put kada prolazimo preko elementa ce se izvristi efekat
    const mainControls = useAnimation();
    const slideControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("end");
            slideControls.start("end");
        }
    }, [isInView]);


    return (
        <div ref={ref} style={{ position: 'relative', width, overflow: 'hidden' }}>
            <motion.div
                variants={{
                    start: { opacity: 0, scale: 0.8 }, // Initial state: faded out and smaller
                    end: { opacity: 1, scale: 1 } // Final state: fully visible and normal size
                }}
                initial="start" // Initial variant
                animate={mainControls} // Animation triggered when in view
                transition={{ duration: 0.5, delay: 0.25 }} // Timing of animation
            >
                {children}
            </motion.div>

            <motion.div
                variants={{
                    start: { left: 0 }, // Slide from the left
                    end: { left: "100%" } // Slide away to the right
                }}
                initial="start"
                animate={slideControls}
                transition={{ duration: 0.5, ease: "easeIn" }}
                style={{
                    position: "absolute",
                    top: 4,
                    bottom: 4,
                    left: 0,
                    right: 0,
                    background: "var(--text-color)",
                    zIndex: 20
                }}
            />



        </div>
    );
};

Reveal.propTypes = {
    children: PropTypes.element.isRequired,
    width: PropTypes.oneOf(['fit-content', '100%'])
};