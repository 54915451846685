import React, { useState } from 'react'

import { Parallax } from 'react-parallax';
import { Reveal } from "../utils/Reveal";

import About1 from '../static/images/about1.jpg'
import About2 from '../static/images/about2.jpg'
import About3 from '../static/images/about3.jpg'
import About4 from '../static/images/about4.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
import '../static/css/index.css'
import '../static/css/about.css'
import { Helmet } from 'react-helmet';

const About = () => {
    return (
        <div className="main-container">
            <Helmet>
                <title>About Drive4Nena - Professional Transportation & Logistics Services</title>
                <meta name="description" content="Learn about Drive4Nena's professional transportation services, our commitment to excellence, and how we deliver reliable freight solutions across the industry." />
                <meta name="keywords" content="transportation services, freight solutions, logistics company, reliable shipping, Drive4Nena" />
                <link rel="canonical" href="/about" />
            </Helmet>
            <Navbar />

            <div className="about" id="about">
                <Parallax className="image"
                    bgImage={About1}
                    bgImageAlt="Drive4Nena transportation facility and fleet"
                    strength={500}>
                    <h2 className="content">
                        Who Are We?
                    </h2>
                </Parallax>
                <div className="about-desc">

                    <h3>
                        <Reveal className="reveal">Passionate About Precision in Every Delivery</Reveal>
                    </h3>

                    <Reveal className="explainer-text">
                        <div className="service-text">
                            Drive4Nena is a dedicated team of transportation professionals passionate about logistics and service excellence. With years of experience, we specialize in providing tailored freight solutions that meet the diverse needs of our clients. Our team is unified by a shared commitment to delivering reliable, on-time service that businesses can count on.
                        </div>
                    </Reveal>

                </div>
                <Parallax className="image"
                    bgImage={About2}
                    bgImageAlt="Modern logistics technology and innovation showcase"
                    strength={500}>
                    <h2 className="content">Improvement & Innovation</h2>
                </Parallax>
                <div className="about-desc">
                    <h3><Reveal className="reveal">Driving Forward with Smart Solutions</Reveal></h3>
                    <Reveal>
                        <div className="service-text">
                            At Drive4Nena, we believe in constantly moving forward. Our commitment to continuous improvement is driven by the desire to deliver better, smarter transportation solutions. We invest in technology and training to enhance efficiency, safety, and service quality, ensuring that we stay ahead of industry trends and client expectations.
                        </div>
                    </Reveal>
                </div>
                <Parallax className="image"
                    bgImage={About3}
                    bgImageAlt="Drive4Nena's reliable transportation fleet in action"
                    strength={500}>
                    <h2 className="content">
                        Reliability & Respect
                    </h2>
                </Parallax>
                <div className="about-desc">
                    <h3><Reveal className="reveal">Building Trust, Mile by Mile</Reveal></h3>
                    <Reveal>
                        <div className="service-text">
                            Reliability is at the core of everything we do. We understand the importance of trust in our industry, which is why we take pride in meeting deadlines and delivering on our promises. Our commitment to respect extends beyond just our clients—it’s about fostering positive relationships with our drivers, partners, and communities.
                        </div>
                    </Reveal>
                </div>
                <Parallax className="image"
                    bgImage={About4}
                    bgImageAlt="Future-focused transportation and logistics solutions"
                    strength={500}>

                    <h2 className="content">
                        Our Vision and Mission
                    </h2>
                </Parallax>
                <div className="about-desc">
                    <h3><Reveal className="reveal">Setting the Standard for the Future of Freight</Reveal></h3>
                    <Reveal>
                        <div className="service-text">
                            Our vision is to be a leader in the transportation industry, setting standards for excellence in service, safety, and innovation. Our mission is to provide seamless, dependable freight solutions while maintaining a relentless focus on sustainability, growth, and customer satisfaction. We aim to build lasting partnerships by exceeding expectations and driving value in every mile.
                        </div>
                    </Reveal>
                </div>
                <div className="about-desc">
                    <Reveal>
                        <div className="service-text">
                            At Drive4Nena, we understand that our success is built on the success of our clients. That's why we remain focused on creating transportation solutions that help businesses thrive. As we continue to grow and innovate, we stay rooted in the values of reliability, respect, and an unwavering commitment to excellence. We look forward to partnering with you for all your transportation needs.
                        </div>
                    </Reveal>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About;