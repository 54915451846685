import React, { useState } from 'react'
import '../static/css/index.css'


const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div className="footer" id="footer">



            <a href="/generaltermsofservice" target="_blank" rel="noopener noreferrer">
                General Terms Of Service
            </a>

            <div>
                Copyright© {currentYear} Drive4Nena.
            </div>

            <div>
                Developed by <a className="developer-link" href="https://dmitar.me" target="_blank" rel="noopener noreferrer">Dmitar</a>
            </div>

        </div>
    )
}

export default Footer;